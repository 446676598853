import logo from './logo.svg';
import './App.css';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isIOS,
  isAndroid
} from "react-device-detect";
import React from 'react';

class App extends React.Component {
  componentDidMount(){
    if(isAndroid){
      window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.limitless"
    }
    else if (isIOS){

    window.location.href = "https://apps.apple.com/gb/app/go-limitless/id1556188945"
  
    }
    else{
      window.location.href = "https://golimitless.com.np/"
  
    }
  }
  render(){
  return (
    <div className="App">
      <header className="App-header">

      </header>
    </div>
  );
}
}

export default App;
